import React, { Component, Fragment } from "react";
import Quagga from "quagga";
import "./Scanner.css";

class Scanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nocamera: false,
      cameras: [],
      selectedCamera: null,
      recentBarcodes: [],
    };
    this.onDetect = this.onDetect.bind(this);
    this.handleCameraSelect = this.handleCameraSelect.bind(this);
  }

  componentDidMount() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
        this.enumerateDevices();
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
      });

    Quagga.onDetected(this.onDetect);
  }

  enumerateDevices() {
    const oneDReaders = ["upc_reader", "ean_reader"];
    const backCameraList = [];

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(function (device) {
          if (device.kind === "videoinput") {
            backCameraList.push({
              deviceLabel: device.label,
              deviceId: device.deviceId,
            });
          }
        });

        this.setState({ cameras: backCameraList }, () => {
          const defaultCamera = backCameraList.find(
            (camera) => camera.deviceLabel.toLowerCase().includes("back")
          ) || backCameraList[0];

          if (defaultCamera) {
            this.initQuagga(defaultCamera.deviceId);
            this.setState({ selectedCamera: defaultCamera.deviceId });
          } else {
            this.initQuagga(null);
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  initQuagga(deviceId) {
    const oneDReaders = ["upc_reader", "ean_reader"];

    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            width: 790,
            height: 490,
            deviceId: deviceId ? deviceId : undefined,
            facingMode: deviceId ? undefined : "environment",
            focusMode: "continuous",
          },
          target: document.querySelector("#barcodeScan"),
          numberOfWorkers: navigator.hardwareConcurrency,
        },
        locate: true,
        decoder: {
          readers: oneDReaders,
        },
      },
      function (err) {
        if (err) {
          return;
        }
        Quagga.start();
      }
    );
  }

  onDetect(res) {
    const MAX_RECENT_BARCODES = 20;

    this.setState(
      (prevState) => {
        const recentBarcodes = prevState.recentBarcodes.concat(
          res.codeResult.code
        );
        return {
          recentBarcodes:
            recentBarcodes.length > MAX_RECENT_BARCODES
              ? recentBarcodes.slice(-MAX_RECENT_BARCODES)
              : recentBarcodes,
        };
      },
      () => {
        if (this.state.recentBarcodes.length >= MAX_RECENT_BARCODES) {
          const counts = {};
          this.state.recentBarcodes.forEach(
            (barcode) => (counts[barcode] = (counts[barcode] || 0) + 1)
          );
          const mostFrequentBarcode = Object.keys(counts).reduce((a, b) =>
            counts[a] > counts[b] ? a : b
          );

          Quagga.stop();
          Quagga.offProcessed();
          this.props.onBarcodeDetect(mostFrequentBarcode);
        } else {
          Quagga.start();
        }
      }
    );
  }

  handleCameraSelect(event) {
    const deviceId = event.target.value;
    this.setState({ selectedCamera: deviceId }, () => {
      Quagga.stop();
      this.initQuagga(deviceId);
    });
  }

  render() {
    const { cameras, selectedCamera } = this.state;

    return (
      <Fragment>
        {cameras.length > 1 && (
          <select value={selectedCamera} onChange={this.handleCameraSelect}>
            {cameras.map((camera, index) => (
              <option key={index} value={camera.deviceId}>
                {camera.deviceLabel}
              </option>
            ))}
          </select>
        )}
        <div id="barcodeScan">

          <div id="focusArea" className="focus-area"></div>
        </div>
      </Fragment>
    );
  }
}

export default Scanner;
