import React, { useState, useRef, useEffect } from "react";
import Scanner from "./Components/Scanner";
import "./App.css"; // Add this line to import the App.css

function App() {
  const [upc, setUpc] = useState("");
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const handleChange = (e) => {
    setUpc(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await fetch(`https://vegornot.azurewebsites.net/api/HttpTrigger1?upc=${upc}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  
    let data = await response.json();
    setResult(data);
    setIsLoading(false);
  };

  const handleScan = (data) => {
    if (data) {
      setUpc(data);
      setIsScanning(false);
      
    }
  };


  const handleError = (err) => {
    console.error(err);
  };

  const toggleScanner = () => {
    setIsScanning(!isScanning);
  };

  return (
    <div className="min-h-screen bg-green-500 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h1 className="text-3xl font-bold mb-6 text-center">Is it Halal?</h1>
        <p className="text-center mb-6">
          Scan a product's barcode or enter its UPC code to check if it's Halal.
        </p>
        <input
          type="text"
          value={upc}
          onChange={handleChange}
          placeholder="Enter UPC code"
          className="border border-gray-300 p-2 rounded w-full mb-4"
        />

        <div className="flex space-x-4 justify-center">
          <button
            onClick={toggleScanner}
            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
          >
            {isScanning ? "Close Scanner" : "Scan Barcode"}
          </button>
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600"
          >
            Check
          </button>
        </div>
        {isScanning && (
          <div className="mt-8 border border-gray-300 w-80 h-40 mx-auto">
            <Scanner onBarcodeDetect={handleScan} />
          </div>
        )}
        {isLoading ? (
          <div className="mt-6 text-center spinner-container">
            <div className="spinner"></div>
          </div>
        ) : (
          result && (
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-4 text-center">Result:</h2>
              {result.product_name && (
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-1">Product Name:</h3>
                  <p className="bg-gray-100 p-2 rounded">{result.product_name}</p>
                </div>
              )}
              {result.ingredients && (
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-1">Ingredients:</h3>
                  <p className="bg-gray-100 p-2 rounded whitespace-pre-wrap">{result.ingredients}</p>
                </div>
              )}
              <h3 className="text-xl font-semibold mb-1">Halal Status:</h3>
              <pre className="bg-gray-100 p-4 rounded text-center whitespace-pre-wrap">{result.response}</pre>
            </div>
          )
        )}
      </div>
    </div>
  );
}


export default App;
